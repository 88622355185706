<template>
  <section class="content px-4 py-2 my-2" name="MassiveOrders">
    <v-layout wrap>
      <v-flex xs12>
        <AppTitle :title="`Envio masivo  #${item.id}`" subtitle="---" />
      </v-flex>
      <v-flex xs12>
        <v-card>
          <AppCardTitle :title="`${orders.length} por enviar`">
            <template slot="button">
              <v-btn
                v-if="orders.filter(e => !e.send).length > 0"
                :disabled="loading"
                small
                depressed
                color="success"
                class="ma-0"
                @click="sendOrdersAction"
              >
                Comenzar el envio
              </v-btn>
            </template>
          </AppCardTitle>
          <v-divider></v-divider>
          <v-data-table
            v-model="selected"
            item-key="id"
            :headers="headers"
            :items="orders"
            :loading="loading"
            rows-per-page-text="Pedidos por pagina"
            no-data-text="No hay cupones por enviar"
            class="elevation-0"
            hide-actions
          >
            <template slot="items" slot-scope="props">
              <tr :active="props.selected">
                <td @click="props.selected = !props.selected">
                  <template v-if="item.email">
                    <v-icon v-if="props.item.send_email === 0" color="info">far fa-pause-circle</v-icon>
                    <v-icon v-else-if="props.item.send_email === 1" color="warning">fas fa-spinner fa-spin</v-icon>
                    <v-icon v-else-if="props.item.send_email === 2" color="success">fas fa-check-circle</v-icon>
                    <v-icon v-else-if="props.item.send_email === 3" color="error">far fa-money-bill-alt</v-icon>
                    <v-icon v-else-if="props.item.send_email === 6" color="error">fas fa-bug</v-icon>
                  </template>
                </td>
                <td @click="props.selected = !props.selected">
                  <template v-if="item.phone">
                    <v-icon v-if="props.item.send_phone === 0" color="info">far fa-pause-circle</v-icon>
                    <v-icon v-else-if="props.item.send_phone === 1" color="warning">fas fa-spinner fa-spin</v-icon>
                    <v-icon v-else-if="props.item.send_phone === 2" color="success">fas fa-check-circle</v-icon>
                    <v-icon v-else-if="props.item.send_phone === 3" color="error">far fa-money-bill-alt</v-icon>
                    <v-icon v-else-if="props.item.send_phone === 6" color="error">fas fa-bug</v-icon>
                  </template>
                </td>
                <td>
                  {{ props.item.id_order }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
import { ENVIOS_MASIVOS, MASSIVE_ORDER, WSP_MSJ } from '../../../config'
import AppTitle from '../../useful/title.vue'
import AppCardTitle from '../../useful/titleCard.vue'
import AppTitleDialog from '../../useful/titleDialog.vue'

function getMonthName(monthNumber) {
  const date = new Date()
  date.setMonth(monthNumber - 1)

  return date.toLocaleString('es-Es', { month: 'long' })
}

export default {
  components: {
    AppTitle,
    AppCardTitle,
    AppTitleDialog
  },
  props: {
    id: {
      type: String || Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      orders: [],
      ordersAll: [],
      selected: [],
      item: null,
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      headers: [
        {
          text: 'Envio Email',
          align: 'left',
          sortable: false,
          value: 'order'
        },
        {
          text: 'Envio Wsp',
          align: 'left',
          sortable: false,
          value: 'order'
        },
        {
          text: 'Pedido',
          align: 'left',
          sortable: false,
          value: 'order'
        }
      ]
    }
  },
  computed: {
    massive() {
      return this.$store.getters.getMassive
    }
  },
  watch: {
    massive(e) {
      if (!e.send) {
        setTimeout(() => {
          if (e.key !== undefined) {
            this.orders[e.key].send_email = 1
            this.orders[e.key].send_phone = 1
            this.sendApi(e.key)
          } else {
            this.loading = false
            this.$store.dispatch('changeSnack', { active: true, text: 'Todos los pedidos enviados' })
          }
        }, 1000)
      }
    }
  },
  mounted() {
    if (this.id) {
      this.getOrders()
    }
  },
  methods: {
    async getOrders() {
      try {
        const res = await this.$http.get(`${ENVIOS_MASIVOS}/${this.id}`)
        this.item = res.data
        this.orders = res.data.orders
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    sendOrdersAction() {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Esta seguro de comenzar el envio?',
        description: 'No cierre la venta hasta que termine el proceso',
        action: this.sendOrders
      })
    },
    sendOrders() {
      this.$store.dispatch('setConfirm', { active: false })
      this.loading = true
      this.dialogSend = false
      this.sendFirst()
    },
    sendFirst() {
      const key = this._.findKey(this.orders, { send: false })
      this.$store.dispatch('changeMassive', {
        key,
        send: false
      })
    },
    async sendApi(key) {
      const res = await this.$http.put(`${ENVIOS_MASIVOS}/${this.item.id}/${this.orders[key].id}`)
      console.log(res)
      this.orders[key].send_email = 2
      this.orders[key].send_phone = 2
      this.orders[key].send = true
      this.sendFirst()
    }
  }
}
</script>
